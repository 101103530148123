// extracted by mini-css-extract-plugin
export var root = "footer-module--root--2HpBl";
export var duckScene = "footer-module--duckScene--3ovPy";
export var duckWrap = "footer-module--duckWrap--3aQPt";
export var water = "footer-module--water--3wgPL";
export var bobbing = "footer-module--bobbing--1H9uY";
export var duckBob = "footer-module--duckBob--2qXDJ";
export var duckBobbing = "footer-module--duckBobbing--L-MQf";
export var water1 = "footer-module--water1--2ca5W";
export var water2 = "footer-module--water2--3-Cny";
export var water3 = "footer-module--water3--oayfk";
export var water4 = "footer-module--water4--Rp6Kr";
export var cloud = "footer-module--cloud--11_RG";
export var cloudWrap1 = "footer-module--cloudWrap1--33Zc9";
export var cloudWrap2 = "footer-module--cloudWrap2--1WGDO";
export var cloudWrap3 = "footer-module--cloudWrap3--r3AMz";
export var cloudWrap4 = "footer-module--cloudWrap4--2W76b";
export var sunWrap = "footer-module--sunWrap--1GGcq";
export var copyright = "footer-module--copyright--3ILFC";