import React, { useState } from "react"
import { DuckLeft, Cloud, Sun } from "@components/duck-scene"
import * as css from "@css/modules/footer.module.scss"

const Footer = () => {
  const [isDuckBobbing, setIsDuckBobbing] = useState(false);
  const handleDuckClick = () => {
    if (!isDuckBobbing) {
      setIsDuckBobbing(true)
      setTimeout(() => {
        setIsDuckBobbing(false)
      }, 2000);
    }
  }
  return (
    <footer className={css.root}>
      <div className={css.duckScene}>
        <div className={`${css.cloud} ${css.cloudWrap1}`}>
          <Cloud />
        </div>
        <div className={`${css.cloud} ${css.cloudWrap2}`}>
          <Cloud />
        </div>
        <div className={`${css.cloud} ${css.cloudWrap3}`}>
          <Cloud />
        </div>
        <div className={`${css.cloud} ${css.cloudWrap4}`}>
          <Cloud />
        </div>
        <div className={css.sunWrap}>
          <Sun />
        </div>
        <div className={`${css.water} ${css.water4}`} />
        <div className={`${css.water} ${css.water3}`} />
        <div className={`${css.water} ${css.water2}`} />
        <div id="footer-duck-clickable" role="button" tabIndex="0" className={css.duckWrap} onKeyDown={handleDuckClick} onClick={handleDuckClick}>
          <div id="duck-left-wrap" className={`${css.duckBob} ${isDuckBobbing ? css.bobbing : ''}`}>
            <DuckLeft/>
          </div>
        </div>
        <div className={`${css.water} ${css.water1}`} />
        <div className={css.copyright}>
          &copy;{new Date().getFullYear()} Ridiculopathy.com / Mark Arenz
        </div>
      </div>
    </footer>
  )
}

export default Footer
