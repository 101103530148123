import React from "react"
import Header from "@components/Header"
import Footer from "@components/Footer"
import Meta from "@components/Meta"
import { Grid } from "@material-ui/core"
import PropTypes from "prop-types"

import "@css/global/app.scss"

const Layout = ({ children }) => {
  return (
    <div className="app">
      <Grid container spacing={0}>
        <Meta
          title="Ridiculopathy - A gallery of highly strange projects"
          metadesc=""
          canonical=""
        />
        <Header />
        <main>{children}</main>
        <Footer />
      </Grid>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
}

export default Layout
