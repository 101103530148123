import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import HeaderDuck from "@components/header-scene/HeaderDuck"
import DuckBill from "@components/header-scene/DuckBill"
import Letters from "@components/header-scene/Letters"
import * as css from "@css/modules/header.module.scss"

const Header = () => {
  const [isDuckQuacking, setIsDuckQuacking] = useState(false)
  const [scrambleState, setScrambleState] = useState(0)
  const handleDuckClick = () => {
    if (!isDuckQuacking) {
      setIsDuckQuacking(true)
      setTimeout(() => {
        setIsDuckQuacking(false)
      }, 2000)
    }
  }
  const handleLettersClick = () => {
    const nextScrambleState = scrambleState >= 3 ? 0 : scrambleState + 1
    setScrambleState(nextScrambleState)
  }

  const letterScrambleClasses = [
    "",
    css.letterScrambleA,
    css.letterScrambleB,
    css.letterScrambleC,
  ]
  return (
    <Grid item xs={12} className={css.root}>
      <img
        src="header-duck.svg"
        alt="Ridiculopathy.com"
        style={{ width: "100%" }}
      />
      <div
        id="header-duck-clickable"
        className={css.duck}
        role="button"
        tabIndex="0"
        onKeyDown={handleDuckClick}
        onClick={handleDuckClick}
      >
        <HeaderDuck />
        <DuckBill isDuckQuacking={isDuckQuacking} />
      </div>
      <div
        id="header-text-clickable"
        className={css.letters}
        role="button"
        tabIndex="0"
        onKeyDown={handleLettersClick}
        onClick={handleLettersClick}
      >
        <Letters letterClass={letterScrambleClasses[scrambleState]} />
      </div>
      <h1>A gallery of strange digital projects.</h1>
    </Grid>
  )
}

export default Header
