import React from "react"

const Sun = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="349.59" height="358.23" viewBox="0 0 349.59 358.23">
      <g>
        <path fill="#c62127" d="M380.67,125a174.33,174.33,0,0,1,172.8,151.39,174.3,174.3,0,0,0-348.1,13.27,176.67,176.67,0,0,0,1.5,22.91q-.5-6.57-.5-13.27A174.3,174.3,0,0,1,380.67,125Z" transform="translate(-205.37 -115.36)" />
        <path fill="#e11e26" d="M553.47,276.38a174.31,174.31,0,0,0-347.1,22.91q0,6.7.5,13.27A174.3,174.3,0,0,0,555,299.29,176.67,176.67,0,0,0,553.47,276.38Z" transform="translate(-205.37 -115.36)" />
      </g>
    </svg>
  )
}

export default Sun
