// extracted by mini-css-extract-plugin
export var root = "header-module--root--aiI7s";
export var duck = "header-module--duck--3x6G2";
export var duckBody = "header-module--duckBody--2R8i4";
export var duckBill = "header-module--duckBill--2N8GB";
export var quacking = "header-module--quacking--1kjnZ";
export var quack = "header-module--quack--11om8";
export var letters = "header-module--letters--2DGzh";
export var lettersSvg = "header-module--lettersSvg--1JiXM";
export var letterScrambleA = "header-module--letterScrambleA--IGSuI";
export var letter3 = "header-module--letter3--EIYBJ";
export var letter6 = "header-module--letter6--2_iyW";
export var letter9 = "header-module--letter9--2mU_C";
export var letter7 = "header-module--letter7--3Y9aa";
export var letter2 = "header-module--letter2--q8yna";
export var letter5 = "header-module--letter5--3wcO1";
export var letter4 = "header-module--letter4--HvhAv";
export var letter11 = "header-module--letter11--2-8zh";
export var letter13 = "header-module--letter13--2vS7Z";
export var letter14 = "header-module--letter14--2PTzt";
export var letter1 = "header-module--letter1--9DbQd";
export var letter8 = "header-module--letter8--2Uigw";
export var letter10 = "header-module--letter10--nMJUT";
export var letter12 = "header-module--letter12--1bbG_";
export var letterScrambleB = "header-module--letterScrambleB--1eFNo";
export var letterScrambleC = "header-module--letterScrambleC--1hpAy";