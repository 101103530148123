import React from "react"
import PropTypes from 'prop-types'
import * as css from "@css/modules/header.module.scss"

const Letters = ({ letterClass }) => {
  return (
    <svg
      className={`${css.lettersSvg} ${letterClass}`}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="406.99"
      height="57.73"
      viewBox="0 0 406.99 57.73"
    >
      <path
        className={css.letter1}
        d="M0,45.1V39.17l4.37-.92V9.89L0,9V3H20.7a21.71,21.71,0,0,1,8.67,1.59A12.82,12.82,0,0,1,35,9.09a12.08,12.08,0,0,1,2,7,12.63,12.63,0,0,1-2,6.88A14.89,14.89,0,0,1,29.52,28a16.12,16.12,0,0,1-8,1.89l-7-.09v8.42l4.37.92V45.1Zm14.51-23h6.3a6.71,6.71,0,0,0,4.54-1.38,5,5,0,0,0,1.59-4,5.87,5.87,0,0,0-1.62-4.32,6.22,6.22,0,0,0-4.62-1.64H14.51Zm12.14,23L21,28.68l8.81-3,5.12,12.52,4,1v5.9Z"
        fill="#231f20"
      />
      <path
        className={css.letter2}
        d="M41.28,45.1V39.17L45,38.25V20.7l-4.36-.93V13.82H55.1V38.25l3.64.92V45.1ZM44.66,7.89V0H55.1V7.89Z"
        fill="#231f20"
      />
      <path
        className={css.letter3}
        d="M72.73,45.7a10.37,10.37,0,0,1-6.31-1.93,12,12,0,0,1-4.05-5.45,21.94,21.94,0,0,1-1.4-8.2v-.61A23.7,23.7,0,0,1,62.38,21a12.92,12.92,0,0,1,4-5.69,9.77,9.77,0,0,1,6.2-2,8.49,8.49,0,0,1,4.29,1,10.22,10.22,0,0,1,3.17,2.87V6.88L75.68,6V0H90.22V38.25l3.64.92V45.1H81.43l-.75-3.88a10.07,10.07,0,0,1-3.35,3.34A8.89,8.89,0,0,1,72.73,45.7Zm3.15-7.77a5.84,5.84,0,0,0,2.37-.49A4.54,4.54,0,0,0,80.08,36V23.44a5.2,5.2,0,0,0-1.78-1.8A4.52,4.52,0,0,0,75.94,21a3.75,3.75,0,0,0-2.8,1.07,6.18,6.18,0,0,0-1.55,3,18.38,18.38,0,0,0-.48,4.45v.61a13,13,0,0,0,1,5.77A3.82,3.82,0,0,0,75.88,37.93Z"
        fill="#231f20"
      />
      <path
        className={css.letter4}
        d="M95.48,45.1V39.17l3.67-.92V20.7l-4.36-.93V13.82H109.3V38.25l3.64.92V45.1ZM98.87,7.89V0H109.3V7.89Z"
        fill="#231f20"
      />
      <path
        className={css.letter5}
        d="M130.11,45.7a15,15,0,0,1-8-2.05A13.63,13.63,0,0,1,117,38a18.09,18.09,0,0,1-1.79-8.18v-.66a17.78,17.78,0,0,1,1.86-8.3,14,14,0,0,1,5.21-5.61,14.7,14.7,0,0,1,7.79-2,19.48,19.48,0,0,1,7.67,1.36,22,22,0,0,1,5.05,2.83l-.18,9.86H135l-1.22-5.67a3.86,3.86,0,0,0-1.31-.81,4,4,0,0,0-1.37-.31,5.62,5.62,0,0,0-3.44.94,5.09,5.09,0,0,0-1.82,2.84,17.91,17.91,0,0,0-.55,4.89v.66a13,13,0,0,0,.73,4.87,5.4,5.4,0,0,0,1.87,2.53,4.12,4.12,0,0,0,2.31.75,4,4,0,0,0,2.82-.91,5.82,5.82,0,0,0,1.54-2.67h8.5l.12.17a18.69,18.69,0,0,1-2.17,6.2,9.11,9.11,0,0,1-4.09,3.7A16.16,16.16,0,0,1,130.11,45.7Z"
        fill="#231f20"
      />
      <path
        className={css.letter6}
        d="M159.57,45.7a9.78,9.78,0,0,1-7.63-3.06q-2.77-3.06-2.77-9.48V20.7l-3.36-.93V13.82h13.5V33.21a5.47,5.47,0,0,0,1,3.52,3.49,3.49,0,0,0,2.9,1.2,6.35,6.35,0,0,0,2.25-.42,5.76,5.76,0,0,0,2-1.26V20.7L164,19.77V13.82h13.56V38.25l3.91.92V45.1H168.74l-.67-3.56a10,10,0,0,1-3.66,3.09A10.63,10.63,0,0,1,159.57,45.7Z"
        fill="#231f20"
      />
      <path
        className={css.letter7}
        d="M183.42,45.1V39.17l3.7-.92V6.88L182.73,6V0h14.54V38.25l3.67.92V45.1Z"
        fill="#231f20"
      />
      <path
        className={css.letter8}
        d="M217.71,45.7a16.07,16.07,0,0,1-8.26-2,13.22,13.22,0,0,1-5.23-5.6,18.42,18.42,0,0,1-1.81-8.34v-.58a18.21,18.21,0,0,1,1.81-8.27,13.42,13.42,0,0,1,5.22-5.62,17.64,17.64,0,0,1,16.43,0,13.44,13.44,0,0,1,5.21,5.61,18.27,18.27,0,0,1,1.8,8.3v.58a18.35,18.35,0,0,1-1.8,8.31,13.41,13.41,0,0,1-5.21,5.62A15.63,15.63,0,0,1,217.71,45.7Zm0-7.72a4.29,4.29,0,0,0,3-1,5.83,5.83,0,0,0,1.62-2.85,17.11,17.11,0,0,0,.5-4.39v-.58a16.52,16.52,0,0,0-.5-4.29A5.86,5.86,0,0,0,220.65,22a4.89,4.89,0,0,0-6,0,5.83,5.83,0,0,0-1.62,2.87,16.55,16.55,0,0,0-.49,4.29v.58a17.28,17.28,0,0,0,.49,4.42A5.53,5.53,0,0,0,214.67,37,4.43,4.43,0,0,0,217.71,38Z"
        fill="#231f20"
      />
      <path
        className={css.letter9}
        d="M236.44,57.12V51.17l3.87-.93V20.7l-4.39-.93V13.82h13.9l.35,3.52a9.4,9.4,0,0,1,3.06-3,8.49,8.49,0,0,1,4.4-1.07,10.17,10.17,0,0,1,6.36,2A12.93,12.93,0,0,1,268.08,21a23.44,23.44,0,0,1,1.43,8.52v.61a21.72,21.72,0,0,1-1.43,8.2A12.24,12.24,0,0,1,264,43.77a10.78,10.78,0,0,1-6.48,1.93,9.15,9.15,0,0,1-4.06-.86,8.53,8.53,0,0,1-3-2.55v8l3.53.93v5.95Zm18-19.14a3.9,3.9,0,0,0,3.81-2.06,12.87,12.87,0,0,0,1.07-5.8v-.61a18.06,18.06,0,0,0-.49-4.48,6.13,6.13,0,0,0-1.58-3A4,4,0,0,0,254.42,21a4.9,4.9,0,0,0-2.36.57,4.11,4.11,0,0,0-1.66,1.74V36.08a3.74,3.74,0,0,0,1.68,1.43A5.78,5.78,0,0,0,254.48,38Z"
        fill="#231f20"
      />
      <path
        className={css.letter10}
        d="M283.56,45.7a10.6,10.6,0,0,1-7.4-2.57,8.75,8.75,0,0,1-2.87-6.85,8.84,8.84,0,0,1,1.58-5.18,10.35,10.35,0,0,1,4.74-3.54,21.39,21.39,0,0,1,7.94-1.28h3.26V24.43a4.87,4.87,0,0,0-1.07-3.2,4.12,4.12,0,0,0-3.38-1.31,6.69,6.69,0,0,0-1.83.24,5.63,5.63,0,0,0-1.55.71l-.69,4h-7.15l-.14-8.7a46,46,0,0,1,5.57-2.05,22.53,22.53,0,0,1,6.48-.87q6.39,0,10.15,2.93A10,10,0,0,1,301,24.54V36.08c0,.44,0,.87,0,1.3a7.73,7.73,0,0,0,.13,1.21l3.39.58V45.1H292.63a10.52,10.52,0,0,1-.81-1.39,7.05,7.05,0,0,1-.6-1.74A10.91,10.91,0,0,1,288,44.72,9.09,9.09,0,0,1,283.56,45.7Zm3.12-7.17a5.84,5.84,0,0,0,2.21-.44,5.16,5.16,0,0,0,1.92-1.35V32.23h-3.15a4.9,4.9,0,0,0-3.16.9,2.88,2.88,0,0,0-1.12,2.37,2.78,2.78,0,0,0,.93,2.22A3.44,3.44,0,0,0,286.68,38.53Z"
        fill="#231f20"
      />
      <path
        className={css.letter11}
        d="M318,45.76q-4.92,0-7.48-2.61T308,34.86v-14h-3.91v-7H308V6.1H318v7.72h5.12v7H318v14a2.73,2.73,0,0,0,.7,2.14,2.77,2.77,0,0,0,1.91.61,9.2,9.2,0,0,0,1-.06c.34,0,.69-.08,1-.14l.69,7.57a23.22,23.22,0,0,1-2.67.59A16.76,16.76,0,0,1,318,45.76Z"
        fill="#231f20"
      />
      <path
        className={css.letter12}
        d="M326,45.1V39.17l3.68-.92V6.88L325.24,6V0h14.48V17.69a10.05,10.05,0,0,1,3.47-3.28A9.7,9.7,0,0,1,348,13.24a9.06,9.06,0,0,1,7.26,3.22q2.75,3.22,2.74,10V38.25l3.65.92V45.1H344.9V39.17l3-.92V26.36c0-2-.35-3.44-1-4.2a3.77,3.77,0,0,0-3-1.14,5.94,5.94,0,0,0-2.4.46A4.12,4.12,0,0,0,339.72,23v15.3l3,.92V45.1Z"
        fill="#231f20"
      />
      <path
        className={css.letter13}
        d="M372,57.73a16.24,16.24,0,0,1-2.68-.22,23.47,23.47,0,0,1-2.76-.62l1.22-7.26a9.46,9.46,0,0,0,1.11.22,8.07,8.07,0,0,0,1.11.07,7.46,7.46,0,0,0,3.28-.68,3.13,3.13,0,0,0,1.81-2.09l.29-1.1L365.31,20.38l-4.19-.61V13.82h18.79v5.95l-3.88.58L380.23,33l0,.23h.18l4.94-13-3.58-.49V13.82H398.9v5.95l-3.7.52L383.49,49.66a16.32,16.32,0,0,1-2.25,4,10.71,10.71,0,0,1-3.67,3A12.33,12.33,0,0,1,372,57.73Z"
        fill="#231f20"
      />
      <path
        className={css.letter14}
        d="M396.91,45.1V36.71H407V45.1Z"
        fill="#231f20"
      />
    </svg>
  )
}

Letters.propTypes = {
  letterClass: PropTypes.string,
}

export default Letters
