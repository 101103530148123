import React from "react"

const Cloud = () => {
  return (
    <svg
      style={{ width: '100%', backgroundCoolor: 'yellow' }}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="600"
      viewBox="0 0 692.55 227.24"
      preserveAspectRatio="none"
    >
      <defs>
        <linearGradient id="cloud-gradient" x1="397.31" y1="228.43" x2="397.31" y2="170.17" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#bbb"/>
          <stop offset="0.4" stopColor="#777"/>
          <stop offset="1" stopColor="#777"/>
        </linearGradient>
      </defs>
      <path fill="url(#cloud-gradient)" d="M397.31,170.17H21.07v58.26c23.52,0,23.52-8.81,47-8.81s23.52,8.81,47,8.81,23.52-8.81,47-8.81,23.51,8.81,47,8.81,23.51-8.81,47-8.81,23.51,8.81,47,8.81,23.51-8.81,47-8.81,23.51,8.81,47,8.81h0c23.51,0,23.51-8.81,47-8.81s23.52,8.81,47,8.81,23.52-8.81,47-8.81,23.52,8.81,47,8.81,23.52-8.81,47-8.81,23.52,8.81,47,8.81,23.52-8.81,47-8.81,23.52,8.81,47,8.81V170.17Z" transform="translate(-21.07 -170.17)" />
    </svg>
  )
}

export default Cloud
