import React from "react"
import PropTypes from "prop-types"
import * as css from "@css/modules/header.module.scss"

const DuckBill = ({ isDuckQuacking }) => {
  return (
    <svg
      className={`${css.duckBill} ${isDuckQuacking ? css.quacking : ""}`}
      id="duckBillSvg"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="28.28"
      height="19.22"
      viewBox="0 0 28.28 19.22"
    >
      <path
        d="M398.73,304.85q6.84,5.83,11.57,9.22l-.59,1A21.32,21.32,0,0,1,401.8,314q-1.33-.45-9.62-4.09c-3.76-1.88-5.79-2.84-6.09-2.9a20.8,20.8,0,0,1-3.32-1.81c-3.21-1.57,4.78-11.1,7-9"
        transform="translate(-382.02 -295.88)"
        fill="#010101"
      />
    </svg>
  )
}

DuckBill.propTypes = {
  isDuckQuacking: PropTypes.bool,
}

export default DuckBill
